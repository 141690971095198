<template>
	<div class="block content pink" v-bind:class="active">
		<div class="block_faux-link">
			<h3>Content</h3>
			<div class="circle-chart">
				<div class="circle">
					<svg viewBox="0 0 36 36" class="chart">
						<defs>
							<filter id="glow">
								<feDropShadow dx="0" dy="0" stdDeviation="0.5" flood-color="#E67497" />
							</filter>
						</defs>
						<path class="track" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
						<path class="circle" style="filter: url(#glow)" v-bind:stroke-dasharray="circleValue + ', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
						<text x="18" y="14" class="reach">Reach</text>
						<text x="18" y="20.35" class="percentage" v-if="getClientContentTreeReach < 1000000">
							{{ numberWithCommas(getClientContentTreeReach) || 0 }}
						</text>
						<text x="18" y="20.35" class="percentage" v-else>
							{{ abbreviateNumber(getClientContentTreeReach) }}
						</text>
					</svg>
				</div>
				<!-- end circle -->
			</div>
			<!-- end circle-chart -->
			<div class="link-block">
				<router-link :to="{path: `/client/${getClientSlug}/content-strategy`}">
					<span class="arrow-link">Content Strategy</span>
				</router-link>
			</div>
			<div class="block_double-links">
				<router-link :to="{path: `/client/${getClientSlug}/content-reach`}">
					<span class="arrow-link">Summary</span>
				</router-link>
				<router-link :to="{path: `/client/${getClientSlug}/content`}">
					<span class="arrow-link">Mycelium</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			loginType: "client",
			// circleValue: "121,767",
		}
	},
	async mounted(){
		try{
			this.$store.dispatch('client/getClientContentTreeReach', this.getClientSlug);
		}catch(error){
			console.log('content block catch error', error);
		}
	},
	computed: {
		...mapGetters("client", ["getClientSlug", "getClientContentTreeReach"]),
	},
}
</script>


<style>
.link-block{
	margin-top: 55px !important;
}
.link-block a{
	padding: 0 !important;
}
.overview-blocks .circle{
	width: 130px !important;
	height: 130px !important;
	padding-top: 20px !important;
}
</style>
